import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ActionType, StatusAPI } from '../../enum'
import { Destinataire, ModalGetByIdRequest } from '../../models'

export interface DestinataireGetByCodePayload {
  code: string
}

export interface DestinataireSearchByNameOrCodePayload {
  data: string
  client_id: string
}

interface currentDestinataireObject {
  status: StatusAPI
  destinataire?: Destinataire
  error: string
  action: ActionType
}

interface currentDestinataireList {
  status: StatusAPI
  destinataireList: Destinataire[]
  loading: boolean
  action: ActionType
}

interface destinataireState {
  currentDestinataire: currentDestinataireObject
  currentDestinataireList: currentDestinataireList
}

const initialState: destinataireState = {
  currentDestinataire: {
    status: StatusAPI.initial,
    destinataire: undefined,
    error: '',
    action: ActionType.NONE,
  },
  currentDestinataireList: {
    status: StatusAPI.initial,
    destinataireList: [],
    loading: false,
    action: ActionType.NONE,
  },
}

const destinataireSlice = createSlice({
  name: 'destinataire',
  initialState,
  reducers: {
    clearCurrentDestinataire(state) {
      state.currentDestinataire = initialState.currentDestinataire
      state.currentDestinataireList = initialState.currentDestinataireList
    },
    getDestinataireByCode(
      state,
      action: PayloadAction<DestinataireGetByCodePayload>
    ) {
      state.currentDestinataire.status = StatusAPI.calling
      state.currentDestinataire.action = ActionType.GET_BY_CODE
    },
    getDestinataireByCodeSuccess(state, action: PayloadAction<Destinataire>) {
      state.currentDestinataire.status = StatusAPI.success
      state.currentDestinataire.destinataire = action.payload
      state.currentDestinataire.action = ActionType.GET_BY_CODE
    },
    getDestinataireByCodeFailed(state, action: PayloadAction<string>) {
      state.currentDestinataire.status = StatusAPI.failure
      state.currentDestinataire.error = action.payload
      state.currentDestinataire.action = ActionType.GET_BY_CODE
    },
    getAllDestinataire(state) {
      state.currentDestinataireList.loading = true
      state.currentDestinataireList.action = ActionType.GET_ALL
    },
    getAllDestinataireSuccess(state, action: PayloadAction<Destinataire[]>) {
      state.currentDestinataireList.loading = false
      state.currentDestinataireList.destinataireList = action.payload
      state.currentDestinataireList.action = ActionType.GET_ALL
    },
    getAllDestinataireFailed(state, action: PayloadAction<string>) {
      state.currentDestinataireList.loading = false
      state.currentDestinataireList.action = ActionType.GET_ALL
    },
    createNewDestinataire(state, action: PayloadAction<Destinataire>) {
      state.currentDestinataire.status = StatusAPI.calling
      state.currentDestinataire.action = ActionType.CREATE
    },
    createNewDestinataireSuccess(state, action: PayloadAction<Destinataire>) {
      state.currentDestinataire.destinataire = action.payload
      state.currentDestinataire.status = StatusAPI.success
      state.currentDestinataire.action = ActionType.CREATE
    },
    createNewDestinataireFailed(state, action: PayloadAction<any>) {
      state.currentDestinataire.error = action.payload
      state.currentDestinataire.status = StatusAPI.failure
      state.currentDestinataire.action = ActionType.CREATE
    },
    searchDestinataireByNameOrCode(
      state,
      action: PayloadAction<DestinataireSearchByNameOrCodePayload>
    ) {
      state.currentDestinataireList.loading = true
      state.currentDestinataireList.action = ActionType.SEARCH_BY_CODE_NOM
    },
    searchDestinataireByNameOrCodeSuccess(
      state,
      action: PayloadAction<Destinataire[]>
    ) {
      state.currentDestinataireList.loading = false
      state.currentDestinataireList.destinataireList = action.payload
      state.currentDestinataireList.action = ActionType.SEARCH_BY_CODE_NOM
    },
    searchDestinataireByNameOrCodeFailed(state, action: PayloadAction<any>) {
      state.currentDestinataireList.loading = false
      state.currentDestinataireList.action = ActionType.SEARCH_BY_CODE_NOM
    },
  },
})

const destinataireReducer = destinataireSlice.reducer
export default destinataireReducer

export const {
  getDestinataireByCode,
  getDestinataireByCodeSuccess,
  getDestinataireByCodeFailed,
  getAllDestinataire,
  getAllDestinataireSuccess,
  getAllDestinataireFailed,
  createNewDestinataire,
  createNewDestinataireSuccess,
  createNewDestinataireFailed,
  searchDestinataireByNameOrCode,
  searchDestinataireByNameOrCodeSuccess,
  searchDestinataireByNameOrCodeFailed,
  clearCurrentDestinataire,
} = destinataireSlice.actions
