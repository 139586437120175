import { TransporteurArrayInterface } from './../models/Transporteur'
import { ModalGetByIdRequest, TransporteurInterface } from '../models'
import baseAxios from './clientAPI'
import { FilterTierParam } from '../redux/reducers/tierSlice'
import { TransporteursCreateUpdateRequest } from '../features/configurations/tier/model/TierModel'

const transporteurApi = {
  getAllTransporteurs(): Promise<TransporteurInterface> {
    const url = '/warehouse/transporteur/all'
    return baseAxios.get(url)
  },

  getTransporteurByCode(payload?: {
    code: string
  }): Promise<TransporteurInterface> {
    const url = `/warehouse/transporteur/get`
    const params = {
      data: payload?.code,
    }
    return baseAxios.post(url, params)
  },

  createTransporteur(payload = {}): Promise<TransporteurInterface> {
    const url = '/warehouse/transporteur'
    return baseAxios.post(url, payload)
  },

  searchTransporteurByNameOrCode(payload: {
    data: string
    client_id: string
  }): Promise<TransporteurArrayInterface> {
    const url = '/warehouse/transporteur/search_by_nom_code'

    return baseAxios.post(url, payload)
  },

  getTransporteurByIdElement(
    payload: ModalGetByIdRequest
  ): Promise<TransporteurInterface> {
    let url = `/warehouse/transporteur/get-by-id-element`
    const params = {
      id: payload.id,
      id_contact: payload.id_contact,
      id_add: payload.id_add,
    }
    return baseAxios.get(url, { params })
  },

  filterTransporteur(
    params: FilterTierParam
  ): Promise<TransporteurArrayInterface> {
    const url = '/warehouse/transporteur/list'
    return baseAxios.get(url, { params })
  },
  deleteTransporteur(id: string) {
    const url = '/warehouse/transporteur/' + id
    return baseAxios.delete(url)
  },
  getTransporteurById(id: string): Promise<TransporteurInterface> {
    const url = '/warehouse/transporteur'
    return baseAxios.get(url, { params: { id } })
  },
  editTransporteur(payload?: {
    id: string
    data: TransporteursCreateUpdateRequest
  }): Promise<TransporteurInterface> {
    const url = `/warehouse/transporteur/${payload?.id}`
    return baseAxios.put(url, { ...payload?.data })
  },
}

export default transporteurApi
