import { t } from 'i18next'
import { Link } from 'react-router-dom'
import { NotiEvent } from '../../enum/NotificationType'
import { Trans } from 'react-i18next'
import { NotificationBell } from '../../models/Notification'
import { extractCodeAndNom } from './func'
import NotiItem from './NotiItem'
import { ClientCompanyWarehouseData } from '../../models'

enum ExportStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
}
type ExportMessage = {
  object?: string
  link_download?: string
  status?: ExportStatus
}
type RapMissionMessage = {
  object?: string
  commande_no?: string
  commande_id?: string
}
type MultipleBlMessage = {
  commande_id?: string
  message?: string
}
type MailImportMessage = {
  file_name?: string
  file_path?: string
  metadata?: string
  class_name?: string
  is_hyperlink?: boolean
  link_download?: string
}
const mapElement = (element?: string) => {
  switch (element) {
    case 'attendu':
      return 'Attendu'
    case 'stock':
      return 'Stock'
    case 'commande':
      return 'Commande'

    default:
      return ''
  }
}

function getDownloadLink(restLink: string) {
  const baseURL = process.env.REACT_APP_API_ENDPOINT
  return `${baseURL}/files/common/export-data/${restLink}`
}

function convertSuccessMessage(message: ExportMessage) {
  const patternSuccessMessage = t('noti.message.exportSuccess')
  const messageSplit = patternSuccessMessage.split('%space')
  return (
    <span>
      <span>{messageSplit[0]}</span>&nbsp;
      <span>{mapElement(message.object)}</span>&nbsp;
      <span>{messageSplit[1]}</span>&nbsp;
      <span>
        <a href={getDownloadLink(message.link_download || '')} target="_blank">
          {t('noti.message.clickHere')}
        </a>
      </span>
      &nbsp;
      <span>{messageSplit[2]}</span>
    </span>
  )
}

function convertFailureMessage(message: ExportMessage) {
  const patternFailureMessage = t('noti.message.exportFailure')
  return (
    <span>
      {patternFailureMessage.replace('%s', mapElement(message.object))}
    </span>
  )
}

export function convertToExportMessage(json: string) {
  try {
    const messageObj = JSON.parse(json) as ExportMessage
    if (messageObj.status === ExportStatus.SUCCESS) {
      return convertSuccessMessage(messageObj)
    }
    return convertFailureMessage(messageObj)
  } catch (error) {
    return ''
  }
}

export function convertToRapMissionMessage(json: string) {
  try {
    const messageObject = JSON.parse(json) as RapMissionMessage
    const messageSplit = t('noti.message.rapMission').split('%s')
    const url = `/gestion-de-stock/commande/commande-edit/${messageObject.commande_id}?page-index=1&page-size=25&must-search=false`
    return (
      <span>
        <span>{messageSplit[0]}</span>
        <span>{messageObject.commande_no}</span>
        <span>{messageSplit[1]}</span>
        <a href={url} target="_blank">
          {t('noti.message.clickHereFirstUpper')}
        </a>
        <span>{messageSplit[2]}</span>
      </span>
    )
  } catch (error) {
    return ''
  }
}

export function convertToMultipleBlMessage(json: string) {
  try {
    const messageObject = JSON.parse(json) as MultipleBlMessage
    const { commande_id, message = '' } = messageObject

    const regex = new RegExp(t('noti.message.multipleBlPattern'))
    const match = message.match(regex)
    if (!match) return ''

    const blNom = match[1]
    const commandeNom = match[2]
    const missionLoadingDraft = match[3]

    const messageSplit = t('noti.message.multipleBlPattern')
      .replaceAll('(.+?)', '%s')
      .replace('(.+)', '%s')
      .split('%s')

    const url = `/gestion-de-stock/commande/commande-envoyer-en-chargement/${commande_id}?page-index=1&page-size=25&count=1`
    return (
      <span>
        <span>{messageSplit[0]}</span>
        <span>{blNom}</span>
        <span>{messageSplit[1]}</span>
        <a href={url} target="_blank">
          {commandeNom}
        </a>
        <span>{messageSplit[2]}</span>
        <span>{missionLoadingDraft}</span>
      </span>
    )
  } catch (error) {
    return ''
  }
}

export function capitalizeFirstLetter(str: string) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }
}

export function getDownloadLinkNotify(
  linkDownload: string = '',
  isHyperlink: boolean = false,
  className: string = 'export-data'
) {
  const baseURL = process.env.REACT_APP_API_ENDPOINT
  if (isHyperlink) {
    return linkDownload
  }
  return `${baseURL}/files/common/${className}/${linkDownload}`
}

function replaceCharacterErrors(errors: string[]): string[] {
  return errors.map((item) => {
    const find = item.indexOf(';;')
    if (find !== -1) {
      return item.replace(';;', ';')
    }
    return item
  })
}

export function convertToEmailImportMessage(
  json: string,
  event: string,
  element: string
) {
  try {
    const messageObject = JSON.parse(json) as MailImportMessage
    const nameEle = capitalizeFirstLetter(element)

    const renderMessage = (i18nKey: string) => (
      <Trans
        i18nKey={i18nKey}
        values={{ filename: messageObject.file_name, element: nameEle }}
        components={{
          customlink: (
            <Link
              to={
                getDownloadLinkNotify(
                  messageObject.link_download,
                  messageObject.is_hyperlink,
                  messageObject.class_name
                ) || ''
              }
            />
          ),
        }}
      />
    )

    switch (event) {
      case NotiEvent.DataBridgeErrorFileHashExists:
        return renderMessage(t('messagedDataBridgeErrorFileHashExists'))
      case NotiEvent.MailDataImportSuccess:
      case NotiEvent.FTPDataImportSuccess:
        return renderMessage(t('mailImportSuccess'))
      case NotiEvent.MailDataImportFailed:
      case NotiEvent.FTPDataImportFailed:
        if (messageObject.metadata) {
          const parsedErrorDetail = JSON.parse(messageObject.metadata)
          const errors = parsedErrorDetail.errors
            ? parsedErrorDetail.errors
            : []
          const updatedErrors = replaceCharacterErrors(errors)

          return (
            <div>
              {renderMessage(t('mailImportFailed'))}
              {updatedErrors.length > 0 &&
                updatedErrors.map((item: string) => <p>{item}</p>)}
            </div>
          )
        }
        break
      default:
        break
    }
  } catch (error) {
    return ''
  }
}

export const mapNotiTooltip = (
  tooltips: NotificationBell[],
  onNotiClick: (
    client_id: string,
    company_id: string,
    warehouse_id: string,
    client_code_nom: string,
    company_code_nom: string,
    warehouse_code_nom: string
  ) => void
) =>
  tooltips.map((tooltip, idx) => {
    const {
      client_id,
      company_id,
      warehouse_id,
      client_code_nom = '',
      company_code_nom = '',
      warehouse_code_nom = '',
      count = 0,
    } = tooltip
    const [codeClient, nomClient] = extractCodeAndNom(client_code_nom)
    const [codeCompany, nomCompany] = extractCodeAndNom(company_code_nom)
    const [codeWarehouse, nomWarehouse] = extractCodeAndNom(warehouse_code_nom)

    return {
      key: `${idx}`,
      label: (
        <NotiItem
          codeClient={codeClient}
          nomClient={nomClient}
          codeCompany={codeCompany}
          nomCompany={nomCompany}
          codeWarehouse={codeWarehouse}
          nomWarehouse={nomWarehouse}
          onNotiClick={() =>
            onNotiClick(
              client_id,
              company_id,
              warehouse_id,
              client_code_nom,
              company_code_nom,
              warehouse_code_nom
            )
          }
          count={count}
        />
      ),
    }
  })

export const mapOthersTooltip = (
  tooltips: NotificationBell[],
  allStandards: ClientCompanyWarehouseData[],
  onNotiClick: (
    client_id: string,
    company_id: string,
    warehouse_id: string,
    client_code_nom: string,
    company_code_nom: string,
    warehouse_code_nom: string
  ) => void
) => {
  const notiSet = new Set(
    tooltips.map((tooltip) => {
      const { client_id, company_id, warehouse_id } = tooltip
      return `${client_id}-${company_id}-${warehouse_id}`
    })
  )
  const others = allStandards.filter((item) => {
    const { client_id, company_id, warehouse_id } = item
    return !notiSet.has(`${client_id}-${company_id}-${warehouse_id}`)
  })
  return others.map((tooltip, idx) => {
    const {
      client_id,
      client_code,
      client_nom,
      company_id,
      company_code,
      company_nom,
      warehouse_id,
      warehouse_code,
      warehouse_nom,
    } = tooltip

    return {
      key: `${idx}`,
      label: (
        <NotiItem
          codeClient={client_code}
          nomClient={client_nom}
          codeCompany={company_code}
          nomCompany={company_nom}
          codeWarehouse={warehouse_code}
          nomWarehouse={warehouse_nom}
          onNotiClick={() =>
            onNotiClick(
              client_id,
              company_id,
              warehouse_id,
              `${client_code}_${client_nom}`,
              `${company_code}_${company_nom}`,
              `${warehouse_code}_${warehouse_nom}`
            )
          }
        />
      ),
    }
  })
}
