import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusAPI, ActionType } from '../../enum'
import { ModalGetByIdRequest, Transporteur } from '../../models'

export interface TransporteurGetByCodePayload {
  code: string
}

export interface TransporteurSearchByNameOrCodePayload {
  data: string
  client_id: string
}

interface currentTransporteurObject {
  status: StatusAPI
  transporteur?: Transporteur
  error: string
  action: ActionType
}

interface currentTransporteurList {
  status: StatusAPI
  transporteurList: Transporteur[]
  loading: boolean
  action: ActionType
}

interface transporteurState {
  currentTransporteur: currentTransporteurObject
  currentTransporteurList: currentTransporteurList
}

const initialState: transporteurState = {
  currentTransporteur: {
    status: StatusAPI.initial,
    transporteur: undefined,
    error: '',
    action: ActionType.NONE,
  },
  currentTransporteurList: {
    status: StatusAPI.initial,
    transporteurList: [],
    loading: false,
    action: ActionType.NONE,
  },
}

const transporteurSlice = createSlice({
  name: 'transporteur',
  initialState,
  reducers: {
    clearCurrentTransporteur(state) {
      state.currentTransporteur = initialState.currentTransporteur
      state.currentTransporteurList = initialState.currentTransporteurList
    },
    getTransporteurByCode(
      state,
      action: PayloadAction<TransporteurGetByCodePayload>
    ) {
      state.currentTransporteur.status = StatusAPI.calling
      state.currentTransporteur.action = ActionType.GET_BY_CODE
    },
    getTransporteurByCodeSuccess(state, action: PayloadAction<Transporteur>) {
      state.currentTransporteur.status = StatusAPI.success
      state.currentTransporteur.transporteur = action.payload
      state.currentTransporteur.action = ActionType.GET_BY_CODE
    },
    getTransporteurByCodeFailed(state, action: PayloadAction<string>) {
      state.currentTransporteur.status = StatusAPI.failure
      state.currentTransporteur.error = action.payload
      state.currentTransporteur.action = ActionType.GET_BY_CODE
    },
    getAllTransporteur(state) {
      state.currentTransporteurList.loading = true
      state.currentTransporteurList.action = ActionType.GET_ALL
    },
    getAllTransporteurSuccess(state, action: PayloadAction<Transporteur[]>) {
      state.currentTransporteurList.loading = false
      state.currentTransporteurList.transporteurList = action.payload
      state.currentTransporteurList.action = ActionType.GET_ALL
    },
    getAllTransporteurFailed(state, action: PayloadAction<string>) {
      state.currentTransporteurList.loading = false
      state.currentTransporteurList.action = ActionType.GET_ALL
    },
    createNewTransporteur(state, action: PayloadAction<Transporteur>) {
      state.currentTransporteur.status = StatusAPI.calling
      state.currentTransporteur.action = ActionType.CREATE
    },
    createNewTransporteurSuccess(state, action: PayloadAction<Transporteur>) {
      state.currentTransporteur.transporteur = action.payload
      state.currentTransporteur.status = StatusAPI.success
      state.currentTransporteur.action = ActionType.CREATE
    },
    createNewTransporteurFailed(state, action: PayloadAction<any>) {
      state.currentTransporteur.error = action.payload
      state.currentTransporteur.status = StatusAPI.failure
      state.currentTransporteur.action = ActionType.CREATE
    },
    searchTransporteurByNameOrCode(
      state,
      action: PayloadAction<TransporteurSearchByNameOrCodePayload>
    ) {
      state.currentTransporteurList.loading = true
      state.currentTransporteurList.action = ActionType.SEARCH_BY_CODE_NOM
    },
    searchTransporteurByNameOrCodeSuccess(
      state,
      action: PayloadAction<Transporteur[]>
    ) {
      state.currentTransporteurList.loading = false
      state.currentTransporteurList.transporteurList = action.payload
      state.currentTransporteurList.action = ActionType.SEARCH_BY_CODE_NOM
    },
    searchTransporteurByNameOrCodeFailed(state, action: PayloadAction<any>) {
      state.currentTransporteurList.loading = false
      state.currentTransporteurList.action = ActionType.SEARCH_BY_CODE_NOM
    },
  },
})

const transporteurReducer = transporteurSlice.reducer
export default transporteurReducer

export const {
  getTransporteurByCode,
  getTransporteurByCodeSuccess,
  getTransporteurByCodeFailed,
  getAllTransporteur,
  getAllTransporteurSuccess,
  getAllTransporteurFailed,
  createNewTransporteur,
  createNewTransporteurSuccess,
  createNewTransporteurFailed,
  searchTransporteurByNameOrCode,
  searchTransporteurByNameOrCodeSuccess,
  searchTransporteurByNameOrCodeFailed,
  clearCurrentTransporteur,
} = transporteurSlice.actions
