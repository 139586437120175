import { Badge, Dropdown, MenuProps } from 'antd'
import BELL_ICON from '../../assets/icons/noti/bell.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import useNotificationBell from './useNotificationBell'
import { NotificationBell } from '../../models/Notification'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { UserRole } from '../../enum/UserRole'
import {
  NOTIFICATION_PATH,
  NOTI_BELL_CLICK_EVENT,
} from '../../hook/useNotiBellClick'
import { extractCodeAndNom } from './func'
import { chooseClient } from '../../redux/reducers/accountSlice'
import { saveClientCompanyWarehouseToLocal } from '../../utils/localStorage'
import { useEffect, useRef } from 'react'
import accessoriesApi from '../../http/accessoriesApi'
import { ClientCompanyWarehouseData } from '../../models'
import NotiItem from './NotiItem'
import { mapNotiTooltip, mapOthersTooltip } from './funcUI'

export default function NotiBell() {
  const { count, currentCount, notificationBells } = useNotificationBell()
  const { choosingClient, choosingCompany, choosingWarehouse } = useSelector(
    (state: RootState) => state.account
  )
  const role = useSelector((state: RootState) => state.permission.role)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const allStandards = useRef<ClientCompanyWarehouseData[]>([])

  useEffect(() => {
    accessoriesApi.getAllClientCompanyWarehouses().then((res) => {
      const data = res?.data?.entry
      if (data) {
        allStandards.current = data
      }
    })
  }, [])

  const genToolTip = () => {
    const tooltips: NotificationBell[] = []
    notificationBells.forEach((value) => {
      if (value.count === 0) return
      tooltips.push(value)
    })

    const items: MenuProps['items'] = mapNotiTooltip(tooltips, onNotiClick)
    const others: MenuProps['items'] = mapOthersTooltip(
      tooltips,
      allStandards.current,
      onNotiClick
    )

    return [...items, ...others]
  }

  const getCountDisplay = () => {
    if (role === UserRole.SUPERADMIN) return ''
    if (!choosingClient || !choosingCompany || !choosingWarehouse) return count
    if (count === 0) return ''
    const currentCountBadge = currentCount > 99 ? '99⁺' : currentCount
    const countBadge = count > 99 ? '99⁺' : count
    return `${currentCountBadge}-${countBadge}`
  }

  const onNotiClick = (
    client_id: string,
    company_id: string,
    warehouse_id: string,
    client_code_nom: string,
    company_code_nom: string,
    warehouse_code_nom: string
  ) => {
    if (pathname == NOTIFICATION_PATH) {
      window.dispatchEvent(
        new CustomEvent(NOTI_BELL_CLICK_EVENT, {
          detail: {
            client_code_nom,
            company_code_nom,
            warehouse_code_nom,
            client_id,
            company_id,
            warehouse_id,
          },
        })
      )
    } else {
      dispatch(
        chooseClient({
          client: client_id,
          company: company_id,
          warehouse: warehouse_id,
        })
      )
      saveClientCompanyWarehouseToLocal(
        client_id || '',
        company_id || '',
        warehouse_id || '',
        client_code_nom || '',
        company_code_nom || '',
        warehouse_code_nom || ''
      )
      setTimeout(() => navigate(NOTIFICATION_PATH), 300)
    }
  }

  return (
    <Dropdown
      placement="bottom"
      menu={{ items: role !== UserRole.SUPERADMIN ? genToolTip() : [] }}
    >
      <Link to={'notifications'}>
        <Badge count={getCountDisplay()} size={'small'} title="">
          <img src={BELL_ICON} className="cursor-pointer" alt="noti" />
        </Badge>
      </Link>
    </Dropdown>
  )
}
