export enum NotificationType {
  LOT = 'lot',
  SSCC = 'sscc',
}

export enum NotiEvent {
  ExportAttendu = "export_attendu",
  ExportCommande = "export_commande",
  ExportStock = "export_stock",
  AlertDluo = 'alerte_dluo',
  RapMission = 'rap_mission',
  PendingMission = 'pending_mission',
  MultiBl = 'multi_bl',
  PrintingJobChange = 'printing_job_change',
  MailDataImportSuccess = 'mail_data_import_success',
  MailDataImportFailed = 'mail_data_import_failed',
  FTPDataImportSuccess = 'ftp_data_import_success',
  FTPDataImportFailed = 'ftp_data_import_failed',
  DataBridgeErrorFileHashExists = 'data_bridge_error_file_hash_exists'
}
