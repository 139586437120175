import React, { ReactNode, useEffect } from 'react'
import { NavBar } from '../Common/Nav/Nav'
import { Navigate, useNavigate } from 'react-router-dom'
import MetaData from '../Common/MetaData'

interface HomeLayoutProps {
  /**
   * content inside HomeLayout
   */
  children: ReactNode
  forHistoryGeneral?: boolean
  tabName?: string
}

/**
 * The @HomeLayout is a main layout includes fixed @NavBar and dynamic children
 * @author [anhht]
 * @version 0.1
 */
const HomeLayout = (props: HomeLayoutProps) => {
  const navigate = useNavigate()
  useEffect(() => {
    if (window.location.pathname === '/') {
      navigate('/tableau-de-bord')
    }
  }, [navigate])

  if (props.forHistoryGeneral) {
    return (
      <div className="flex flex-col  h-screen w-screen">
        <div className="top-0 w-full z-50">
          <NavBar />
        </div>
        <div className="flex-1 overflow-auto">{props.children}</div>
      </div>
    )
  }

  return (
    <>
      <MetaData title={props.tabName || ''} />
      <div className="mt-16" />
      {props.children}
    </>
  )
}

export default HomeLayout
