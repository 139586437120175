import { ReactNode, useEffect } from 'react'

interface MetaDataProps {
  title: string
  children?: ReactNode
}

const MetaData = (props: MetaDataProps) => {
  useEffect(() => {
    if (props.title) {
      document.title = `${props.title} | Equateur`
    } else {
      document.title = 'Equateur'
    }
  }, [props.title])

  return <>{props?.children}</>
}

export default MetaData
