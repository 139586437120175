import { isNil } from 'lodash'
import { FC } from 'react'

interface NotiItemProps {
  codeClient: string
  nomClient: string
  codeCompany: string
  nomCompany: string
  codeWarehouse: string
  nomWarehouse: string
  onNotiClick: () => void
  count?: number
}

const NotiItem: FC<NotiItemProps> = ({
  codeClient,
  nomClient,
  codeCompany,
  nomCompany,
  codeWarehouse,
  nomWarehouse,
  onNotiClick,
  count,
}) => {
  return (
    <div className="text-base" onClick={onNotiClick}>
      <span>
        <span className="font-bold">{codeClient}</span>_
        <span className="text-gray">{nomClient}</span> |&nbsp;
        <span className="font-bold">{codeCompany}</span>_
        <span className="text-gray">{nomCompany}</span> |&nbsp;
        <span className="font-bold">{codeWarehouse}</span>_
        <span className="text-gray">{nomWarehouse}</span>
      </span>
      {count && (
        <>
          :&nbsp;
          <span className="text-red font-bold">{count}</span>
        </>
      )}
    </div>
  )
}

export default NotiItem
