import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { all, call, fork, put, take, takeLatest } from 'redux-saga/effects'
import transporteurApi from '../../http/transporteurApi'
import {
  ModalGetByIdRequest,
  Transporteur,
  TransporteurArrayInterface,
  TransporteurInterface,
} from '../../models'
import { getAllAttenduFailed } from '../reducers/attenduSlice'
import {
  createNewTransporteur,
  createNewTransporteurFailed,
  createNewTransporteurSuccess,
  getAllTransporteur,
  getAllTransporteurSuccess,
  getTransporteurByCode,
  getTransporteurByCodeFailed,
  getTransporteurByCodeSuccess,
  searchTransporteurByNameOrCode,
  searchTransporteurByNameOrCodeFailed,
  searchTransporteurByNameOrCodeSuccess,
  TransporteurGetByCodePayload,
  TransporteurSearchByNameOrCodePayload,
} from '../reducers/transporteurSlice'

function* fetchAllTransporteurs() {
  try {
    const item: TransporteurArrayInterface = yield call(
      transporteurApi.getAllTransporteurs
    )
    yield put(getAllTransporteurSuccess(item.data.entry))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getAllAttenduFailed(error.message))
    } else {
      yield put(getAllAttenduFailed('Something went wrong'))
    }
  }
}

function* fetchTransporteurByCode(payload: TransporteurGetByCodePayload) {
  try {
    const item: TransporteurInterface = yield call(
      transporteurApi.getTransporteurByCode,
      payload
    )
    yield put(getTransporteurByCodeSuccess(item.data.entry))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getTransporteurByCodeFailed(error.message))
    } else {
      yield put(getTransporteurByCodeFailed('Something went wrong'))
    }
  }
}

function* createTransporteur(payload: Transporteur) {
  try {
    const item: TransporteurInterface = yield call(
      transporteurApi.createTransporteur,
      payload
    )
    yield put(createNewTransporteurSuccess(item.data.entry))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(createNewTransporteurFailed(error.response?.data.error))
    } else {
      yield put(createNewTransporteurFailed('Something went wrong'))
    }
  }
}

function* searchTransporteur(payload: TransporteurSearchByNameOrCodePayload) {
  try {
    const item: TransporteurArrayInterface = yield call(
      transporteurApi.searchTransporteurByNameOrCode,
      payload
    )
    yield put(searchTransporteurByNameOrCodeSuccess(item.data.entry))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(
        searchTransporteurByNameOrCodeFailed(error.response?.data.error)
      )
    } else {
      yield put(searchTransporteurByNameOrCodeFailed('Something went wrong'))
    }
  }
}

function* watchTransporteurFlow() {
  yield takeLatest(getAllTransporteur, fetchAllTransporteurs)
  while (true) {
    const type: PayloadAction<TransporteurGetByCodePayload> = yield take(
      getTransporteurByCode.type
    )
    if (type) {
      yield fork(fetchTransporteurByCode, type.payload)
    }
  }
}

function* watchCreateTransporteurFlow() {
  while (true) {
    const transporteur: PayloadAction<Transporteur> = yield take(
      createNewTransporteur.type
    )
    if (transporteur) {
      yield fork(createTransporteur, transporteur.payload)
    }
  }
}

function* watchSearchTransporteurlow() {
  while (true) {
    const type: PayloadAction<TransporteurSearchByNameOrCodePayload> =
      yield take(searchTransporteurByNameOrCode.type)
    if (type) {
      yield fork(searchTransporteur, type.payload)
    }
  }
}

export default function* transporteurSaga() {
  yield all([
    fork(watchTransporteurFlow),
    fork(watchCreateTransporteurFlow),
    fork(watchSearchTransporteurlow),
  ])
}
