import moment from 'moment'
import {
  UserDetail,
  RoleData,
  EditingRoleData,
  UserForm,
  ViewInfo,
} from '../../../../../models'
import { AppPermission } from '../../../../../models/Permission'
import { PrintingPolicyData } from '../../../../../models/Printing'

export const INIT_USER: UserDetail = {
  active: true,
  code: '',
  id: '',
  mail: '',
  name: '',
  pre_name: '',
  roles: [],
  created_at: 0,
  updated_at: 0,
  views: [],
}

export const INIT_FORM: UserForm = {
  nom: '',
  pre_nom: '',
  mail: '',
  actif: true,
  role: '',
  code: '',
  password: '',
}

export const INIT_SELECTED_ROLE_DATA: EditingRoleData = {
  client: '',
  company: '',
  warehouse: '',
  client_id: '',
  company_id: '',
  warehouse_id: '',
  description: '',
}

export const INIT_PRINTING_POLICY: PrintingPolicyData = {
  id: '',
  warehouse: '',
  warehouse_id: '',
  printer: {
    id: '',
    name: '',
    ip: '',
    label: '',
    value: '',
  },
  code: '',
  printer_id: '',
  user_id: '',
  policy_type: '',
  detailed_policies: {
    media: undefined,
    tray: undefined,
    documents: [],
  },
  created_at: 0,
  updated_at: 0
}

export const INIT_ROLE_DATA: RoleData = {
  id: '',
  client: '',
  company: '',
  warehouse: '',
  updatedAt: moment().unix(),
  description: '',
  client_id: '',
  company_id: '',
  warehouse_id: '',
  role_template_id: '',
  canEdit: true,
  isEditing: true,
  hasError: false,
  is_original_permission: true,
}

export enum Entity {
  Dashboard = 'dashboard',
  Stock = 'stock',
  Attendu = 'attendu',
  Commande = 'commande',
  Mission = 'mission',
}

export enum Permission {
  ReadStock = 'read_stock',
  ReadPersonel = 'read_personel',
  ReadWarehouse = 'read_warehouse',
  Update = 'update',
  Create = 'create',
  Read = 'read',
  Delete = 'delete',
  UpsertStock = 'upsert_stock',
  UpsertAttendu = 'upsert_attendu',
  UpsertCommande = 'upsert_commande',
  DeleteStock = 'delete_stock',
  DeleteAttendu = 'delete_attendu',
  DeleteCommande = 'delete_commande',
  Import = 'import',
  Export = 'export',
}

export const INIT_APP_PERMISSIONS: AppPermission = {
  mission: {
    reception: true,
    putaway: true,
    reception_putaway: true,
    movement: true,
    regularization_plus: true,
    regularization_minus: true,
    preparation_completed: true,
    preparation_picking: true,
    loading: true,
    rap: true,
    re_cdn: true,
    return_to_stock: true,
    inventory: true,
  },
  management: {
    manual_location: true,
    affectation: true,
    forcing: true,
  },
}

export const APP_PERMISSION_LABEL = {
  reception: 'Réception a quai',
  putaway: 'Mise au stock',
  reception_putaway: 'Réception & Mise au stock',
  movement: 'Mouvements',
  regularization_plus: 'Régularisation +',
  regularization_minus: 'Régularisation -',
  preparation_completed: 'Préparation complète',
  preparation_picking: 'Préparation picking',
  loading: 'Chargement',
  rap: 'Réappro',
  re_cdn: 'ReCdn',
  return_to_stock: 'Remise au Stock',
  inventory: 'Inventaire',
  manual_location: 'Emplacement manuel',
  affectation: 'Affectation',
  forcing: 'Forçage',
}

export const missionHasLevel = (key: string) =>
  [
    'putaway',
    'reception_putaway',
    'movement',
    'rap',
    're_cdn',
    'return_to_stock',
  ].includes(key)

export const MIN_LEVEL = 0
export const MAX_LEVEL = 9

export const INIT_STOCK_VIEW_DATA: ViewInfo[] = [
  {
    title: 'Mouvement',
    menu: 'mouvement',
    display: true,
  },
  {
    title: 'Etat',
    menu: 'etat',
    display: true,
  },
  {
    title: 'Produit',
    menu: 'produit',
    display: true,
  },
  {
    title: 'Conditionnement',
    menu: 'conditionnement',
    display: true,
  },
  {
    title: 'Qte',
    menu: 'qte',
    display: true,
  },
  {
    title: 'Sous-Qte',
    menu: 'sousqte',
    display: true,
  },
  {
    title: 'Total pièce',
    menu: 'totalPiece',
    display: true,
  },
  {
    title: 'Palette',
    menu: 'palette',
    display: true,
  },
  {
    title: 'DLUO',
    menu: 'dluo',
    display: true,
  },
  {
    title: 'Statut',
    menu: 'statut',
    display: true,
  },
  {
    title: 'Emplacement',
    menu: 'emplacement',
    display: true,
  },
  {
    title: 'BL',
    menu: 'bl',
    display: true,
  },
  {
    title: 'Chrono',
    menu: 'chrono',
    display: true,
  },
  {
    title: 'Entrée',
    menu: 'entree',
    display: true,
  },
  {
    title: 'Lot',
    menu: 'lot',
    display: true,
  },
  {
    title: 'Fabrication',
    menu: 'fabrication',
    display: true,
  },
  {
    title: 'Libération',
    menu: 'liberation',
    display: true,
  },
  {
    title: 'Création',
    menu: 'creation',
    display: true,
  },
  {
    title: 'Modification',
    menu: 'modification',
    display: true,
  },
  {
    title: 'Validation',
    menu: 'validation',
    display: true,
  },
]

export const INIT_ATTENDU_VIEW_DATA: ViewInfo[] = [
  {
    title: 'Mouvement',
    menu: 'mouvement',
    display: true,
  },
  {
    title: 'Etat',
    menu: 'etat',
    display: true,
  },
  {
    title: 'Produit',
    menu: 'produit',
    display: true,
  },
  {
    title: 'Conditionnement',
    menu: 'conditionnement',
    display: true,
  },
  {
    title: 'Qte',
    menu: 'qte',
    display: true,
  },
  {
    title: 'Sous-Qte',
    menu: 'sousqte',
    display: true,
  },
  {
    title: 'Total pièce',
    menu: 'total_pieses',
    display: true,
  },
  {
    title: 'Palette',
    menu: 'palette',
    display: true,
  },
  {
    title: 'DLUO',
    menu: 'dluo',
    display: true,
  },
  {
    title: 'Statut',
    menu: 'statut',
    display: true,
  },
  {
    title: 'BL',
    menu: 'bl',
    display: true,
  },
  {
    title: 'Commande',
    menu: 'commande',
    display: true,
  },
  {
    title: 'Fournisseur',
    menu: 'fournisseur',
    display: true,
  },
  {
    title: 'Chrono',
    menu: 'chrono',
    display: true,
  },
  {
    title: 'Lot',
    menu: 'lot',
    display: true,
  },
  {
    title: 'Création',
    menu: 'creation',
    display: true,
  },
  {
    title: 'Modification',
    menu: 'modification',
    display: true,
  },
  {
    title: 'Validation',
    menu: 'validation',
    display: true,
  },
  {
    title: 'Ligne',
    menu: 'ligne',
    display: true,
  },
]

export const INIT_COMMANDE_VIEW_DATA: ViewInfo[] = [
  {
    title: 'Mouvement',
    menu: 'mouvement',
    display: true,
  },
  {
    title: 'Etat',
    menu: 'etat',
    display: true,
  },
  {
    title: 'Produit',
    menu: 'produit',
    display: true,
  },
  {
    title: 'Conditionnement',
    menu: 'conditionnement',
    display: true,
  },
  {
    title: 'Qte',
    menu: 'qte',
    display: true,
  },
  {
    title: 'Sous-Qte',
    menu: 'sousqte',
    display: true,
  },
  {
    title: 'Total pièce',
    menu: 'totalPiece',
    display: true,
  },
  {
    title: 'Palette',
    menu: 'palette',
    display: true,
  },
  {
    title: 'DLUO',
    menu: 'dluo',
    display: true,
  },
  {
    title: 'Statut',
    menu: 'statut',
    display: true,
  },
  {
    title: 'Emplacement',
    menu: 'emplacement',
    display: true,
  },
  {
    title: 'BL',
    menu: 'bl',
    display: true,
  },
  {
    title: 'Commandes',
    menu: 'commande',
    display: true,
  },
  {
    title: 'Destinataire',
    menu: 'destinataire',
    display: true,
  },
  {
    title: 'Transporteur',
    menu: 'transport',
    display: true,
  },
  {
    title: 'Chrono',
    menu: 'chrono',
    display: true,
  },
  {
    title: 'Chargement',
    menu: 'chargement',
    display: true,
  },
  {
    title: 'Entrée',
    menu: 'entree',
    display: true,
  },
  {
    title: 'Lot',
    menu: 'lot',
    display: true,
  },
  {
    title: 'Fabrication',
    menu: 'fabrication',
    display: true,
  },
  {
    title: 'Libération',
    menu: 'liberation',
    display: true,
  },
  {
    title: 'Création',
    menu: 'creation',
    display: true,
  },
  {
    title: 'Modification',
    menu: 'modification',
    display: true,
  },
  {
    title: 'Validation',
    menu: 'validation',
    display: true,
  },
  {
    title: 'Tracking',
    menu: 'tracking',
    display: true,
  },
  {
    title: 'Ligne',
    menu: 'ligne',
    display: true,
  },
]

export const VUE_ENTITY_OPTIONS = [
  {
    label: 'Stock',
    value: 'stock',
  },
  {
    label: 'Attendu',
    value: 'attendu',
  },
  {
    label: 'Commande',
    value: 'commande',
  },
]

export const STOCK_MENU_TITLE = {
  mouvement: 'Mouvement',
  etat: 'Etat',
  produit: 'Produit',
  conditionnement: 'Conditionnement',
  qte: 'Qte',
  sousqte: 'Sous-Qte',
  totalPiece: 'Total pièce',
  palette: 'Palette',
  dluo: 'DLUO',
  statut: 'Statut',
  emplacement: 'Emplacement',
  bl: 'BL',
  chrono: 'Chrono',
  entree: 'Entrée',
  lot: 'Lot',
  fabrication: 'Fabrication',
  liberation: 'Libération',
  creation: 'Création',
  modification: 'Modification',
  validation: 'Validation',
}

export const ATTENDU_MENU_TITLE = {
  mouvement: 'Mouvement',
  etat: 'Etat',
  produit: 'Produit',
  conditionnement: 'Conditionnement',
  qte: 'Qte',
  sousqte: 'Sous-Qte',
  total_pieses: 'Total pièce',
  palette: 'Palette',
  dluo: 'DLUO',
  statut: 'Statut',
  bl: 'BL',
  commande: 'Commandes',
  fournisseur: 'Fournisseur',
  chrono: 'Chrono',
  lot: 'Lot',
  creation: 'Création',
  modification: 'Modification',
  validation: 'Validation',
  ligne: 'Ligne',
  totalPiece: 'Total pièce',
  emplacement: 'Emplacement',
  destinataire: 'Destinataire',
  transport: 'Transporteur',
  chargement: 'Chargement',
  entree: 'Entrée',
  fabrication: 'Fabrication',
  liberation: 'Libération',
  tracking: 'Tracking',
}

export const COMMANDE_MENU_TITLE = {
  mouvement: 'Mouvement',
  etat: 'Etat',
  produit: 'Produit',
  conditionnement: 'Conditionnement',
  qte: 'Qte',
  sousqte: 'Sous-Qte',
  totalPiece: 'Total pièce',
  palette: 'Palette',
  dluo: 'DLUO',
  statut: 'Statut',
  emplacement: 'Emplacement',
  bl: 'BL',
  commande: 'Commandes',
  destinataire: 'Destinataire',
  transport: 'Transporteur',
  chrono: 'Chrono',
  chargement: 'Chargement',
  entree: 'Entrée',
  lot: 'Lot',
  fabrication: 'Fabrication',
  liberation: 'Libération',
  creation: 'Création',
  modification: 'Modification',
  validation: 'Validation',
  tracking: 'Tracking',
  ligne: 'Ligne',
}

export const CANNOT_EDIT_ROLE_ERROR =
  'current user does not have permission to edit role'
