import {
  ClientCompanyWarehouseData,
  CwcFilterPayload,
  ListClientAffectationInterface,
  ListUserManagerInterface,
} from '../models'
import baseAxios from './clientAPI'

const accessoriesApi = {
  getCompanyWarehouse(): Promise<ListClientAffectationInterface> {
    const url = '/warehouse/accessories/company-warehouse'
    return baseAxios.get(url)
  },

  getCompanyWarehouseByClientId(
    clientId: string,
    params: {
      is_block: boolean
    }
  ): Promise<ListClientAffectationInterface> {
    const { is_block } = params
    const url = `/warehouse/accessories/company-warehouse/${localStorage.getItem(
      'user_id'
    )}/${clientId}?is_block=${is_block}`
    return baseAxios.get(url)
  },
  checkDluoWithWarehouseTime(warehouse_id: string, dluo: number) {
    const url = '/warehouse/accessories/valid-warning-dluo'
    const params = { warehouse_id, dluo }
    return baseAxios.get(url, { params })
  },

  getManagerUsers(data: CwcFilterPayload): Promise<ListUserManagerInterface> {
    const searchParams = new URLSearchParams(data)
    const url = `/warehouse/accessories/filter-managers?${searchParams.toString()}`
    return baseAxios.get(url)
  },

  getAllClientCompanyWarehouses(): Promise<{
    data: { entry: ClientCompanyWarehouseData[] }
  }> {
    const url = '/warehouse/accessories/client-warehouse-company'
    return baseAxios.get(url)
  },
}

export default accessoriesApi
